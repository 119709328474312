import React, { Component } from "react";
import { Link } from "gatsby";
import { UncontrolledCollapse } from "reactstrap";
import BurgerBtn from "./BurgerBtn";
import UbicacionPop from "./UbicacionPop";

import logo from "../images/barcelona-eventos.png";

class Header extends Component {
	render() {
		return (
			<header className="main-header">
				<div className="main-header-cont">
					<div className="top">
						<div className="info">
							<a href="tel:8110860167" className="btn-icon">
								<svg
									width="35"
									height="35"
									xmlns="http://www.w3.org/2000/svg"
								>
									<g fillRule="nonzero" fill="none">
										<path
											d="M17.5 1C8.387 1 1 8.387 1 17.5S8.387 34 17.5 34 34 26.613 34 17.5 26.613 1 17.5 1z"
											stroke="currentcolor"
										/>
										<path
											d="M19.229 18.229c-1.583 1.582-3.417 3.096-4.142 2.371-1.037-1.037-1.677-1.941-3.965-.102-2.287 1.838-.53 3.064.475 4.068 1.16 1.16 5.484.062 9.758-4.211 4.273-4.274 5.368-8.598 4.207-9.758-1.005-1.006-2.225-2.762-4.063-.475-1.839 2.287-.936 2.927.103 3.965.722.725-.791 2.559-2.373 4.142z"
											fill="currentcolor"
										/>
									</g>
								</svg>
								<span>T. (81)1086-0167</span>
							</a>
							<UbicacionPop btnClass="btn-icon">
								<svg
									width="35"
									height="35"
									xmlns="http://www.w3.org/2000/svg"
								>
									<g fill="none" fillRule="evenodd">
										<path
											d="M17.5 1C8.387 1 1 8.387 1 17.5S8.387 34 17.5 34 34 26.613 34 17.5 26.613 1 17.5 1z"
											stroke="currentcolor"
										/>
										<path
											d="M18 9c3.825.096 6.904 3.154 7 6.955a7.667 7.667 0 0 1-.93 3.21l-.054.093a55.416 55.416 0 0 1-2.127 3.16C19.746 25.413 18.84 26 18 26c-.537 0-1.8 0-6.032-6.738l-.054-.097a7.666 7.666 0 0 1-.914-3.21c.096-3.801 3.175-6.86 7-6.955zm0 3a3 3 0 1 0 0 6 3 3 0 0 0 0-6z"
											fill="currentcolor"
										/>
									</g>
								</svg>
								<span>¿Cómo llegar?</span>
							</UbicacionPop>
						</div>

						<div className="logo">
							<Link to="/">
								<img
									src={logo}
									alt="Barcelona Eventos"
									width="172"
									height="97"
								/>
							</Link>
						</div>

						<div className="social">
							<span>Síguenos en redes</span>
							<a
								href="https://www.facebook.com/barcelonaeventosapodaca"
								title="Facebook"
								target="_blank"
								rel="noopener noreferrer"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="20"
									height="20"
									fill="currentColor"
									className="bi bi-facebook"
									viewBox="0 0 16 16"
								>
									<path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
								</svg>
							</a>

							<a
								href="https://www.instagram.com/barcelona.eventosapodaca/"
								title="Instagram"
								target="_blank"
								rel="noopener noreferrer"
								className="btn-icon"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="20"
									height="20"
									fill="currentColor"
									className="bi bi-instagram"
									viewBox="0 0 16 16"
								>
									<path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
								</svg>
							</a>
						</div>
						<div className="nav-toggler">
							<BurgerBtn></BurgerBtn>
						</div>
					</div>
					<div className="nav-cont">
						<nav>
							<ul>
								<li>
									<Link to="/nosotros">Nosotros</Link>
								</li>
								<li>
									<Link to="/saint-jordi">
										Salón Saint Jordi
									</Link>
								</li>
								<li>
									<Link to="/plaza-cataluna">
										Salón Plaza Cataluña
									</Link>
								</li>
								<li>
									<Link to="/eventos">Eventos</Link>
								</li>
								<li>
									<Link to="/catering">Catering</Link>
								</li>
								<li>
									<Link to="/noticias">Noticias</Link>
								</li>
								<li>
									<Link to="/contacto">Contacto</Link>
								</li>
							</ul>
						</nav>
					</div>
					<div className="mobile-nav-cont">
						<UncontrolledCollapse toggler="#toggler">
							<div className="inner">
								<nav>
									<ul>
										<li>
											<Link to="/nosotros">Nosotros</Link>
										</li>
										<li>
											<Link to="/saint-jordi">
												Salón Saint Jordi
											</Link>
										</li>
										<li>
											<Link to="/plaza-cataluna">
												Salón Plaza Cataluña
											</Link>
										</li>
										<li>
											<Link to="/eventos">Eventos</Link>
										</li>
										<li>
											<Link to="/catering">Catering</Link>
										</li>
										<li>
											<Link to="/noticias">Noticias</Link>
										</li>
										<li>
											<Link to="/contacto">Contacto</Link>
										</li>
									</ul>
								</nav>
								<div className="info">
									<a
										href="tel:(81)19669700"
										className="btn-icon"
									>
										<svg
											width="35"
											height="35"
											xmlns="http://www.w3.org/2000/svg"
										>
											<g fillRule="nonzero" fill="none">
												<path
													d="M17.5 1C8.387 1 1 8.387 1 17.5S8.387 34 17.5 34 34 26.613 34 17.5 26.613 1 17.5 1z"
													stroke="currentcolor"
												/>
												<path
													d="M19.229 18.229c-1.583 1.582-3.417 3.096-4.142 2.371-1.037-1.037-1.677-1.941-3.965-.102-2.287 1.838-.53 3.064.475 4.068 1.16 1.16 5.484.062 9.758-4.211 4.273-4.274 5.368-8.598 4.207-9.758-1.005-1.006-2.225-2.762-4.063-.475-1.839 2.287-.936 2.927.103 3.965.722.725-.791 2.559-2.373 4.142z"
													fill="currentcolor"
												/>
											</g>
										</svg>
										<span>T. (81)1966-9700</span>
									</a>
									<UbicacionPop btnClass="btn-icon">
										<svg
											width="35"
											height="35"
											xmlns="http://www.w3.org/2000/svg"
										>
											<g fill="none" fillRule="evenodd">
												<path
													d="M17.5 1C8.387 1 1 8.387 1 17.5S8.387 34 17.5 34 34 26.613 34 17.5 26.613 1 17.5 1z"
													stroke="currentcolor"
												/>
												<path
													d="M18 9c3.825.096 6.904 3.154 7 6.955a7.667 7.667 0 0 1-.93 3.21l-.054.093a55.416 55.416 0 0 1-2.127 3.16C19.746 25.413 18.84 26 18 26c-.537 0-1.8 0-6.032-6.738l-.054-.097a7.666 7.666 0 0 1-.914-3.21c.096-3.801 3.175-6.86 7-6.955zm0 3a3 3 0 1 0 0 6 3 3 0 0 0 0-6z"
													fill="currentcolor"
												/>
											</g>
										</svg>
										<span>¿Cómo llegar?</span>
									</UbicacionPop>
								</div>
							</div>
						</UncontrolledCollapse>
					</div>
				</div>
			</header>
		);
	}
}

export default Header;

import React, { Component } from "react"

class BurgerBtn extends Component{

	constructor(props) {
		super(props);
		this.state = {
			isOpen: true,
		};

		this.toggle = this.toggle.bind(this);
	  }

	toggle() {
		this.setState(prevState => ({
			isOpen: !prevState.isOpen
		}));
	}

	render(){
		return(
			<button
				onClick={this.toggle}
				className={`hamburger hamburger--spin ${ this.state.isOpen ? true : 'is-active' } `}
				type="button"
				aria-label="Menu"
				aria-controls="navigation"
				id="toggler"
			>
				<span className="hamburger-box">
					<span className="hamburger-inner"></span>
				</span>
			</button>
		)
	}
}

export default BurgerBtn

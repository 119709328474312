import React, { Component, Fragment } from "react"

import gmaps from '../images/gmaps.png'
import waze from '../images/waze.png'
import croquis from '../images/croquis.png'

class UbicacionPop extends Component{

	constructor(props) {
		super(props);
		this.state = {
			isOpen: false,
		};

		this.toggle = this.toggle.bind(this);
	  }

	toggle() {
		this.setState(prevState => ({
			isOpen: !prevState.isOpen
		}));
	}

	render(){
		return(
			<Fragment>
				<button onClick={this.toggle} className={this.props.btnClass}>
					{this.props.children}
				</button>

				<div className={`ubicacion-pop ${ this.state.isOpen ? true : 'is-hidden' } `}>
					<div className="cerrar" onClick={this.toggle}>
						<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x-circle"><circle cx="12" cy="12" r="10"></circle><line x1="15" y1="9" x2="9" y2="15"></line><line x1="9" y1="9" x2="15" y2="15"></line></svg>
					</div>
					<div className="inner">
						<h6>¿Sábes como llegar a Barcelona Eventos?</h6>
						<p>Boulevard Julián Treviño Elizondo, 66634 Apodaca, Nuevo León.</p>
						<a href="https://www.google.com/maps/place/Barcelona+Eventos+Apodaca/@25.7446913,-100.1862066,17z/data=!3m1!4b1!4m5!3m4!1s0x8662ebb9316b256f:0x7755f87688902eb6!8m2!3d25.7446913!4d-100.1840126" target="_blank" rel="noopener noreferrer" className="btn d-inline-block btn-outline blanco mr-3 mb-3">
							<img className="mr-2" src={gmaps} alt="Google Maps" width="27" height="27"/>
							Abrir en google maps
						</a>
						<a href="https://www.waze.com/ul?ll=25.74469130%2C-100.18401260&navigate=yes&zoom=16" target="_blank" rel="noopener noreferrer" className="btn d-inline-block btn-outline blanco mr-3 mb-3">
							<img className="mr-2" src={waze} alt="Waze" width="28" height="26"/>
							Abrir en waze
						</a>
						<a href={croquis} target="_blank" rel="noopener noreferrer" className="btn d-inline-block btn-outline blanco mr-3 mb-3">
							<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-download mr-2"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="7 10 12 15 17 10"></polyline><line x1="12" y1="15" x2="12" y2="3"></line></svg>
							Descargar croquis
						</a>
					</div>
				</div>
			</Fragment>
		)
	}
}

export default UbicacionPop

import React from 'react'

const Suscribete = () => {
	return (
		<section className="suscribete-widget">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-lg-6 text-center text-lg-left">
						<h6>Suscríbete a nuestro boletín</h6>
						<p>Entérate de las últimas noticias y eventos próximos en tu cuenta de correo.</p>
					</div>
					<div className="col-lg-3 text-center text-lg-right">
						<a className="btn btn-solid gold extra-padding" href="http://eepurl.com/gJXRhz" target="_blank" rel="noopener noreferrer">Suscribirme</a>
					</div>
				</div>
			</div>
		</section>
	)
}

export default Suscribete
